// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import { type ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserService from '../../../services/User.service';
import { Button } from '../../atoms';
import { Icon } from '../../atoms/Icon/Icon';
import { TwilioRoom, TwilioRoomMessage } from '../../organisms/TwilioRoom/TwilioRoom';

export function Appointment(): ReactElement {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  if (typeof appointmentId !== 'string') {
    throw new Error('appointmentId param is must be string');
  }

  const navigate = useNavigate();

  const [name, setName] = useState<string>();
  const [token, setToken] = useState<string>();
  const [error, setError] = useState<string>('');

  // Fetch the Twilio token from the API on component load.
  useEffect(() => {
    const getToken = async () => {
      try {
        const { data } = await UserService.getTwilioPatientAppointmentToken(appointmentId);
        setToken(data.data.jwt);
        setName(data.data.room);
      } catch (err) {
        console.log(err);
        setError('Authorization error');
      }
    };
    getToken();
  }, [appointmentId]);

  const connectingMessage = 'Warten, bis das Zimmer bereit ist...';
  const waitingMessage = 'Ihr Arzt wird in wenigen Augenblicken hier sein...';

  return (
    <div
      style={{
        backgroundColor: '#111',
        color: 'white',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
      }}
    >
      <header
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '1rem',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Icon icon='nowomed' size={200} color='white' />
        <Button onClick={() => navigate('/')} style={{ display: 'block', margin: '0 0 0 auto', padding: '0.5rem' }}>
          Anruf beenden
        </Button>
      </header>
      <div
        style={{
          paddingTop: '3rem',
          height: 'calc(100vh - 3rem)',
        }}
      >
        {error ? (
          <TwilioRoomMessage message={error} />
        ) : name && token ? (
          <TwilioRoom name={name} token={token} waitingMessage={waitingMessage} connectingMessage={connectingMessage} />
        ) : (
          <TwilioRoomMessage message={connectingMessage} />
        )}
      </div>
    </div>
  );
}
